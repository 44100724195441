<template>
  <div id="receive_order">
    <div class="admin_main_block">
      <div class="user_temp_title">底单申请</div>
      <div class="admin_form_main">
        <div class="daifatu_home">
          <el-form
            label-width="100px"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
          >
            <el-form-item label="快递单号" size="small" prop="order_nos">
              <el-input
                v-model="ruleForm.order_nos"
                size="small"
                type="textarea"
                :autosize="{ minRows: 14, maxRows: 14 }"
                placeholder="快递单号，多个换行隔开"
              ></el-input>
            </el-form-item>
            <el-form-item label="Email" size="small" prop="email">
              <el-input
                v-model="ruleForm.email"
                size="small"
                type="text"
                placeholder="请输入email"
              ></el-input>
              <font color="#48a025">* 仅提供被降权的订单</font>
            </el-form-item>
            <el-form-item size="small">
              <el-button type="primary" size="small" @click="add"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table :data="list" v-loading="loading">
          <el-table-column label="编号" width="100" prop="id"></el-table-column>
          <el-table-column
            label="快递单号"
            prop="expressNos"
            width="250"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="接收邮件" prop="email"></el-table-column>
          <el-table-column label="申请状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">已申请</span>
              <span v-if="scope.row.status == 1">已处理</span>
            </template>
          </el-table-column>
          <el-table-column label="申请时间" prop="createTime">
            <template slot-scope="scope">
              {{ (scope.row.createTime / 1000) | date("Y-m-d H:i:s") }}
            </template>
          </el-table-column>
        </el-table>
        <div class="home_fy_block">
          <el-pagination
            v-if="total > 0"
            background
            @current-change="handleCurrentChange"
            :current-page="searchData.pageNo"
            :page-size="searchData.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      ruleForm: {
        order_nos: "",
        email: "",
      },
      rules: {
        order_nos: {
          required: true,
          message: "快递单号不能为空",
          trigger: "blur",
        },
        email: { required: true, message: "email不能为空", trigger: "blur" },
      },
      loading: false,
      searchData: {
        pageNo: 1,
        pageSize: 10,
      },
      list: [],
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  watch: {},
  methods: {
    add() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.loading) return;
          this.loading = true;
          const loading = this.$loading();
          var expressNos = "";
          var strList = this.ruleForm.order_nos.split("\n");
          for (var i = 0; i < strList.length; i++) {
            if (strList[i]) {
              expressNos += strList[i];
              if (i != strList.length - 1) {
                expressNos += ",";
              }
            }
          }
          this.$request.post({
            url: "/ReportApi/business/saleBottomSheet/saveSaleBottomSheet",
            params: {
              tenantId: localStorage.getItem("tenantInfoid"),
              id: localStorage.getItem("ids"),
              expressNos: expressNos,
              email: this.ruleForm.email,
            },
            success: (res) => {
              this.$message.success("提交成功");
              this.ruleForm.order_nos = "";
              this.ruleForm.email = "";
              this.getList();
            },
            finally: () => {
              this.loading = false;
              loading.close();
            },
          });
        } else {

          return false;
        }
      });
    },
    getList() {
      this.searchData.id = localStorage.getItem("ids");
      this.loading = true;
      this.$request.post({
        url: "/ReportApi/business/saleBottomSheet/bottomSheetList",
        params: this.searchData,
        success: (res) => {
          this.list = res.data;
          this.total = res[1];
        },
        finally: () => {
          this.loading = false;
        },
      });
    },
    handleCurrentChange(val) {
      this.searchData.pageNo = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
#receive_order {
  .admin_main_block {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    min-height: 855px;
    border-radius: 5px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .admin_form_main {
      .el-form-item {
        width: 400px;
        margin-bottom: 18px;
      }
    }
  }
  .home_fy_block {
    margin-top: 30px;
    text-align: right;
    margin-right: 10px;
  }
}
</style>
